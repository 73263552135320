<i18n src="@/locales/osteoporosis.json" />

<template>
  <div class="ostheoporosis">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <p>
        {{ $t('message_1') }}
      </p>
    </b-message>
    <ImanH2>
      {{ $t('h2_title_1') }}
    </ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          {{ $t('article_p1_1') }}
        </p>
        <p>
          {{ $t('article_p1_2') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_1') }}
        </h3>
        <p>
          {{ $t('article_p2_1') }}
        </p>
        <p>
          {{ $t('article_p2_2') }}
        </p>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_2') }}
        </h3>
        <p>
          {{ $t('article_p3_1') }}
        </p>
        <div v-html="$t('article_p3_2')" />
        <p>
          {{ $t('article_p3_3') }}
        </p>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_3') }}
        </h3>
        <p>
          {{ $t('article_p4_1') }}
        </p>
        <div v-html="$t('article_p4_2')" />
        <p>
          {{ $t('article_p4_3') }}
        </p>
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_4') }}</ImanH3>
        <p>
          {{ $t('article_p5') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3>
          {{ $t('h3_title_5') }}
        </ImanH3>
        <p>
          <span v-html="$t('article_p6')" />
        </p>
        <ImanMoreInfo :path="$t('more_info_path_3')">
          {{ $t('more_info_3') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard :title="$t('collapse_title_1')">
          <p>
            {{ $t('collapse_p1') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_4')">
            {{ $t('more_info_4') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_2')">
          <p>
            {{ $t('collapse_p2_1') }}
          </p>
          <p>
            {{ $t('collapse_p2_2') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_5')">
            {{ $t('more_info_5') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_3')">
          <p>
            {{ $t('collapse_p3') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_4')">
          <p>
            {{ $t('collapse_p4') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_6')">
            {{ $t('more_info_6') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_5')">
          <p>
            {{ $t('collapse_p5') }}
          </p>
        </ImanCollapsableCard>
      </div>
    </section>
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'Osteoporosis',
    components: {
      ImanMoreInfo
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-implant/basal-implant/comparative/ostoeporosis/osteoporose-definition.jpg',
            position: 'right',
            alt: 'ostéoporose, définition image d\'os malade et os sein'
          },
          {
            path: 'dental-implant/basal-implant/comparative/ostoeporosis/implant-dentaire-osteoporose-risque.jpg',
            position: 'right',
            alt: 'implant dentaire osteoporose risque'
          },
          {
            path: 'dental-implant/basal-implant/comparative/ostoeporosis/implant-dentaire-osteoporose-sans-risque.jpg',
            position: 'right',
            alt: 'implant dentaire osteoporose sans risque'
          }
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.2.4.4.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/ostoeporosis/H1-implant-dentire-ostheoporose-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/ostoeporosis/H1-implant-dentire-ostheoporose-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/ostoeporosis/H1-implant-dentire-ostheoporose-m.jpg");
    }
  }
</style>
